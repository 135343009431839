import React, { useState, useEffect } from "react";

const SimpleCountdownTimer = ({
  duration, // Durée du décompte en millisecondes
  size = 200, // Taille du cercle
  color = "#FFFFFF", // Couleur du texte
  onTimerComplete = () => console.log("finished simple timer"),
  className = "din", // Classe de style pour le texte
  show = true, // Pour contrôler la visibilité
  start = false, // Pour démarrer le décompte
  pause = false, // Pour mettre le décompte en pause
}) => {
  const [timeRemaining, setTimeRemaining] = useState(duration);
  const [isActive, setIsActive] = useState(false); // État pour savoir si le timer est actif

  useEffect(() => {
    if (start) {
      setIsActive(true); // Démarrer le timer si `start` est vrai
    } else if (pause) {
      setIsActive(false); // Mettre en pause le timer si `pause` est vrai
    }
  }, [start, pause]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const remaining = timeRemaining - 1000;
  //     if (remaining < 1000) onTimerComplete();
  //     setTimeRemaining(remaining);
  //   }, 1000);
  //   return () => clearInterval(interval);
  //  },[timeRemaining])

  useEffect(() => {
    let interval;

    if (isActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(interval);
            onTimerComplete(); // Appeler la fonction de complétion
            return 0; // Arrêter à 0 quand le temps est écoulé
          }
          return prevTime - 1000; // Réduire d'une seconde
        });
      }, 1000);
    }

    // Nettoyer l'intervalle lors du démontage ou si le timer n'est pas actif
    return () => clearInterval(interval);
  }, [isActive, timeRemaining]);

  // Fonction de formatage du temps (heures, minutes, secondes)
  const formatTime = (timeInMs) => {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  // Si `show` est faux, on ne retourne rien
  if (!show) {
    return null;
  }

  return (
    <div
      className={className}
      style={{
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: "100%",
        borderWidth: "2px",
        borderColor: isActive ? color : "rgba(255,255,255,0.5)",
        borderStyle: "solid",
      }}
    >
      {/* Texte du décompte */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: isActive ? color : "rgba(255,255,255,0.5)",
        }}
      >
        <h1 style={{ fontSize: `${size / 7}px`, margin: "0px" }}>
          {formatTime(timeRemaining)}
        </h1>
      </div>
    </div>
  );
};

export default SimpleCountdownTimer;
